import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'page'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete pages',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['moreFromMy' + Ns + 'Title']: 'More of my pages',
            ['moreFromUser' + Ns + 'Title']: 'More pages',

            [ns + 'SortRelevance']: 'Relevance',
            [n + 'LabelsMore']: 'More authors',
            [n + 'ClickToActivate']: 'Click to activate'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Pages',
            ['my' + Ns + 'Menu']: 'My pages',
            ['new' + N + 'Menu']: 'New page',
            [ns + 'Menu']: 'Pages',
            ['deleted' + Ns + 'Menu']: 'Deleted pages',
            ['banned' + Ns + 'Menu']: 'Permanently deleted pages',
            ['protection' + Ns + 'Menu']: 'Protected pages',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new page has been saved',
            ['save' + N + 'SuccessMessage']: 'The page has been saved',
            ['delete' + N + 'Success']: 'The page has been deleted',
            ['delete' + Ns + 'Success']: 'The pages have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the page?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the pages?',
            ['ban' + N + 'Success']: 'The page has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the page?',
            ['approve' + N + 'Success']: 'The page has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the page?',
            ['protection' + N + 'Success']: 'The page has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the page as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no pages'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Page',
            [ns + 'Title']: 'Pages',
            ['new' + N + 'Title']: 'New page',
            ['edit' + N + 'Title']: 'Edit page',
            ['user' + Ns + 'Title']: 'Pages',
            ['userDeleted' + Ns + 'Title']: 'Deleted pages',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted pages',
            ['my' + Ns + 'Title']: 'My pages',
            ['myDeleted' + Ns + 'Title']: 'My deleted pages',
            ['myBanned' + Ns + 'Title']: 'My permanently deleted pages',

            ['dialogDelete' + N + 'Title']: 'Delete page',
            ['dialogBan' + N + 'Title']: 'Permanently delete page',
            ['dialogApprove' + N + 'Title']: 'Approve page',
            ['dialogProtection' + N + 'Title']: 'Mark page as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove protection from page',

            ['dialogDelete' + Ns + 'Title']: 'Delete pages',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted pages',
            ['deleted' + Ns + 'Title']: 'Deleted pages',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected pages',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select pages',
            ['select' + N]: 'Select page'
        }
    }
}
