import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import priceWithSpaces from './priceWithSpaces'

export default function getConstants(p = {}) {

    const name = 'order'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Order',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['continuousShopping' + N + 'Text']: 'Continue shopping',
            ['checkout' + N + 'Text']: 'Finalize order',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete orders',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',

            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },

            [ns + 'SortRelevance']: 'Relevance',
            [n + 'TotalText']: ({ total }) => (total) ? '' + priceWithSpaces(Math.round(total)) + ' Ft' : '0 Ft',
            [n + 'ProductTitleLabel']: 'Product',
            [n + 'QuantityLabel']: 'Quantity',
            [n + 'TotalTextLabel']: 'Total',
            [n + 'CartSummaryLabel']: 'Total price of products',
            [n + 'NetPriceLabel']: 'Net price',
            [n + 'BrPriceLabel']: 'Gross price',
            [n + 'SummaryLabel']: 'Summary',

            [n + 'InvoiceData']: 'Invoice data',
            [n + 'InvoiceAddress']: 'Invoice address',
            [n + 'ShippingData']: 'Shipping data',
            [n + 'Payments']: 'Payments',
            [n + 'ContentOrderData']: 'Order data',

            [n + 'CartUpdateCartLabel']: 'Update cart',
            [n + 'SummaryProducts']: 'Products',
            [n + 'OtherFees']: 'Other fees',

            ['redirectToBarionSubmit']: 'OK',

            [n + 'BarionInfoTitle']: 'Card payment with Barion system',
            [n + 'BarionInfoDesc']: 'By clicking on the order button, the page will redirect you to the Barion payment page.',
            [n + 'TotalPriceWithCurrency']: (total) => priceWithSpaces(total) + ' Ft',

            [n + 'EditInvoiceData']: 'Edit invoice data',

            [n + 'OpenPaymentsSection']: 'Show payments',
            [n + 'ClosePaymentsSection']: 'Close',

            [n + 'SearchLabel']: 'Search by order ID',
            [n + 'SearchLabelForAdmin']: 'Search by order ID or user'

        },
        menus: {
            ...menus,
            ['user' + Ns + 'MainMenu']: 'Orders',
            ['my' + Ns + 'MainMenu']: 'My orders',
            ['user' + Ns + 'Menu']: 'Orders',
            ['user' + Ns + 'UnpaidMenu']: 'Unpaid',
            ['user' + Ns + 'ProcessingMenu']: 'Processing',
            ['user' + Ns + 'SuccessMenu']: 'Completed',
            ['my' + Ns + 'Menu']: 'My orders',
            ['new' + N + 'Menu']: 'New order',
            [ns + 'Menu']: 'Orders',
            ['deleted' + Ns + 'Menu']: 'Deleted orders',
            ['banned' + Ns + 'Menu']: 'Permanently deleted orders',
            ['protection' + Ns + 'Menu']: 'Protected orders',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The order has been saved',
            ['save' + N + 'SuccessMessage']: 'The order has been saved',
            ['delete' + N + 'Success']: 'The order has been deleted',
            ['delete' + Ns + 'Success']: 'The orders have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the order?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the orders?',
            ['ban' + N + 'Success']: 'The order has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the order?',
            ['approve' + N + 'Success']: 'The order has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the order?',
            ['protection' + N + 'Success']: 'The order has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the order as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no orders',

            [n + 'EmptyCart']: 'The cart is empty',
            [n + 'AddToCart']: 'Add products',
            [n + 'ChooseProductsCart']: 'Choose products',

            ['redirectToBarionDialogMessage']: 'Redirecting you to the Barion page, please wait...',
            [n + 'NewPaymentInfoInOrderContent']: ({ lastPayment }) => {
                if (lastPayment?.paymentStatus === 20) {
                    return `The Barion transaction has started but not yet completed. Would you like to restart the payment?`
                } else if (lastPayment?.paymentStatus === 10) {
                    return `The Barion transaction has been rejected. Would you like to restart the payment?`
                }
            }
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Order',
            [ns + 'Title']: 'Orders',
            ['new' + N + 'Title']: 'New order',
            ['edit' + N + 'Title']: 'Edit order',
            ['user' + Ns + 'Title']: 'Orders',
            ['user' + Ns + 'UnpaidTitle']: 'Unpaid orders',
            ['user' + Ns + 'ProcessingTitle']: 'Processing orders',
            ['user' + Ns + 'SuccessTitle']: 'Completed orders',
            ['userDeleted' + Ns + 'Title']: 'Deleted orders',
            ['my' + Ns + 'Title']: 'My orders',
            ['myDeleted' + Ns + 'Title']: 'My deleted orders',

            ['dialogDelete' + N + 'Title']: 'Delete order',
            ['dialogBan' + N + 'Title']: 'Permanently delete order',
            ['dialogApprove' + N + 'Title']: 'Approve order',
            ['dialogProtection' + N + 'Title']: 'Mark order as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove protection from order',

            ['dialogDelete' + Ns + 'Title']: 'Delete orders',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted orders',
            ['deleted' + Ns + 'Title']: 'Deleted orders',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected orders',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select orders',
            ['select' + N]: 'Select order',

            [n + 'ShippingData']: 'Shipping data',
            [n + 'InvoiceData']: 'Invoice data',

            ['redirectToBarionDialogTitle']: 'Redirect'
        }
    }
}
