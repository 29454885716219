import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const { name = 'post' } = p

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    return {
        labels: {
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete entries',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['date' + N + 'Format']: ({ dateText = '', dateOptions = {} }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        ...dateOptions
                    })
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevance',
            [n + 'CoverLabel']: 'Cover Image',
            [n + 'AuthorLabel']: 'Author',
            [n + 'TitleLabel']: 'Title',
            listLabel: '',
            selectableListLabel: 'Select from the list',
            clearSelectedItems: 'Clear all selections',
            selectedItems: 'Selected items',
            selectedItemsPlaceholder: 'No items selected',
            [ns + 'OrSelectFromList']: 'Or select from the list',
            [ns + 'SelectFromListOpen']: 'Select',
            [ns + 'OrSelectFromListOpenLabel']: 'Or select from the list',
            pageInfoPerPage: ({ startItem, endItem, allItem }) => `${startItem} - ${endItem}, total: ${allItem}`,
            [n + 'ShowButtonLabel']: 'Show',
            [n + 'ShowButtonInactiveLabel']: 'Inactive',
            [n + 'OfAuthor']: ({ author }) => author?.title ? author?.title + ' entries' : 'User entries',
            [n + 'InfiniteScrollLoadMoreLabel']: 'Load more',
            [n + 'PaginationPrevPage']: 'Previous page',
            [n + 'PaginationNextPage']: 'Next page',
            [n + 'PostInDrawerOpenInNewTab']: 'Open in new tab',
            [n + 'PostInDrawerClose']: 'Close',
            [n + 'CarouselNextLabel']: 'Next',
            [n + 'CarouselPrevLabel']: 'Previous'
        },
        menus: {
            ['user' + Ns + 'Menu']: 'Entries',
            ['my' + Ns + 'Menu']: 'My entries',
            ['new' + N + 'Menu']: 'New entry',
            [ns + 'Menu']: 'Entries',
            ['deleted' + Ns + 'Menu']: 'Deleted entries',
            ['banned' + Ns + 'Menu']: 'Permanently deleted entries',
            ['protection' + Ns + 'Menu']: 'Protected entries',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ['new' + N + 'SuccessMessage']: 'The new entry has been saved',
            ['save' + N + 'SuccessMessage']: 'The entry has been saved',
            ['delete' + N + 'Success']: 'The entry has been deleted',
            ['delete' + Ns + 'Success']: 'The entries have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the entry?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the entries?',
            ['ban' + N + 'Success']: 'The entry has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the entry?',
            ['approve' + N + 'Success']: 'The entry has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the entry?',
            ['protection' + N + 'Success']: 'The entry has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the entry as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no entries',
            [name + 'ValidationLengthText']: ({ min, max, value }) => {
                if ((min >= 1 && value?.length < min)) {
                    return `At least ${min} characters required`
                }
                if (max >= 1 && value?.length > max) {
                    return `Maximum ${max} characters allowed`
                }
            },
            [name + 'ValidationNumber']: ({ min, max, value }) => {
                if (typeof min === 'number' && (value || value === 0) && value < min) {
                    return `Minimum ${min} required`
                }
                if (typeof max === 'number' && (value || value === 0) && value > max) {
                    return `Maximum ${max} allowed`
                }
            },
            [name + 'ValidationLengthArray']: ({ min, max, value }) => {
                if ((min >= 1 && value?.length < min)) {
                    return `At least ${min} options must be selected`
                }
                if (max >= 1 && value?.length > max) {
                    return `Maximum ${max} options can be selected`
                }
            },
            [name + 'ValidationRegex']: () => 'Invalid format',
            [name + 'ValidationRequired']: () => 'This field is required',
            [name + 'CountOfCharacters']: ({ length }) => {
                return 'Number of characters: ' + length.toString()
            }
        },
        routes: {
            ['user' + Ns + 'Route']: '/' + ns,
            [n + 'Route']: '/' + n,
            [ns + 'Route']: '/' + ns
        },
        titles: {
            [n + 'Title']: 'Entry',
            [ns + 'Title']: 'Entries',
            ['new' + N + 'Title']: 'New entry',
            ['edit' + N + 'Title']: 'Edit entry',
            ['user' + Ns + 'Title']: 'Entries',
            ['userDeleted' + Ns + 'Title']: 'Deleted entries',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted entries',
            ['my' + Ns + 'Title']: 'My entries',
            ['myDeleted' + Ns + 'Title']: 'My deleted entries',
            ['myBanned' + Ns + 'Title']: 'My permanently deleted entries',

            ['dialogDelete' + N + 'Title']: 'Delete entry',
            ['dialogBan' + N + 'Title']: 'Delete entry permanently',
            ['dialogApprove' + N + 'Title']: 'Approve entry',
            ['dialogProtection' + N + 'Title']: 'Mark entry as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove protection from entry',

            ['dialogDelete' + Ns + 'Title']: 'Delete entries',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted entries',
            ['deleted' + Ns + 'Title']: 'Deleted entries',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected entries',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select entries',
            ['select' + N]: 'Select entry'
        }
    }
}
