import { runPostTypesConfigSync } from '../../postTypes'

const titles = {
    homeTitle: 'Home',
    acceptCookiesTitle: 'Accept Privacy',
    notFoundTitle: 'This content is not available',
    leaveWarningTitle: 'Leaving Page',
    newReleaseWarningTitle: 'The application has been updated',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'titles' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default titles
