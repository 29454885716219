import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import priceWithSpaces from '../order/priceWithSpaces'

export default function getConstants(p = {}) {

    const name = 'payment'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Start payment',
            ['restart' + N + 'SubmitLabel']: 'Restart payment',
            ['newOnline' + N + 'SubmitLabel']: 'Online payment',
            ['newCash' + N + 'SubmitLabel']: 'Cash payment',
            ['newTransfer' + N + 'SubmitLabel']: 'Transfer payment',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Select a purchase',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete payments',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('en-US')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevance',
            [n + 'TitleLabel']: 'Payment ID',
            [n + 'AuthorLabel']: 'Payer',
            [n + 'OpenConnectedOrderText']: 'Open',

            [n + 'BarionInfoTitle']: 'Card payment with Barion system',
            [n + 'BarionInfoDesc']: 'By clicking the purchase button, the page will redirect to the Barion payment page.',
            [n + 'TotalPriceWithCurrency']: (total) => priceWithSpaces(total) + ' Ft',

            [n + 'SearchLabel']: 'Search by payment ID'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Payments',
            ['my' + Ns + 'Menu']: 'My payments',
            ['new' + N + 'Menu']: 'Start payment',
            [ns + 'Menu']: 'Payments',
            ['deleted' + Ns + 'Menu']: 'Deleted payments',
            ['banned' + Ns + 'Menu']: 'Permanently deleted payments',
            ['protection' + Ns + 'Menu']: 'Protected payments',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Payment saved',
            ['save' + N + 'SuccessMessage']: 'Payment saved',
            ['delete' + N + 'Success']: 'Payment deleted',
            ['delete' + Ns + 'Success']: 'Payments deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the payment?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the payments?',
            ['ban' + N + 'Success']: 'Payment permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the payment?',
            ['approve' + N + 'Success']: 'Payment approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the payment?',
            ['protection' + N + 'Success']: 'Payment marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the payment as protected?',
            ['removeProtection' + N + 'Success']: 'Protection removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no payments',
            [n + 'AutoSubmit']: (s) => s ? 'Redirecting to the Barion page in ' + s + ' seconds' : 'Redirecting to the Barion page...'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Payment',
            [ns + 'Title']: 'Payments',
            ['new' + N + 'Title']: 'Start payment',
            ['edit' + N + 'Title']: 'Edit payment',
            ['user' + Ns + 'Title']: 'Payments',
            ['userDeleted' + Ns + 'Title']: 'Deleted payments',
            ['my' + Ns + 'Title']: 'My payments',
            ['myDeleted' + Ns + 'Title']: 'Deleted payments',

            ['dialogDelete' + N + 'Title']: 'Delete payment',
            ['dialogBan' + N + 'Title']: 'Permanently delete payment',
            ['dialogApprove' + N + 'Title']: 'Approve payment',
            ['dialogProtection' + N + 'Title']: 'Mark payment as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove payment protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete payments',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted payments',
            ['deleted' + Ns + 'Title']: 'Deleted payments',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected payments',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select payments',
            ['select' + N]: 'Select payment'
        }
    }
}
