import React from 'react'
import clsx from 'clsx'

import DoneIcon from 'digby-ui/dist/common/src/svg/DoneIcon'
import SvgIcon from 'digby-ui/dist/common/src/components/SvgIcon'

import Button from 'digby-ui/dist/common/src/components/Button'
import CloseIcon from 'digby-ui/dist/common/src/svg/CloseIcon'

export const CustomCheckbox = React.forwardRef((p, ref) => {

    const {
        children,
        active,
        disabled,
        color,
        size,
        variant,
        effect,
        disableElevation,
        enableBackground,
        _classNames,
        ...props
    } = p

    const defaultButtonProps = {
        Component: 'div',
        size: 'checkbox-' + (size.split('-')[1]),
        variant: 'contained',
        icon: active ? <DoneIcon /> : <SvgIcon />,
        color: disabled ? 'disabled' : active ? 'primary' : color,
        active,
        style: {
            transition: 'none'
        },
        effect,
        disableElevation,
        disabled
    }

    return (
        <div {...props} className={clsx(_classNames.customCheckbox)} ref={ref}>
            <Button {...defaultButtonProps} />
        </div>
    )
})

export const CustomCheckboxRemove = React.forwardRef((p, ref) => {

    const {
        children,
        active,
        disabled,
        color,
        size,
        variant,
        effect,
        disableElevation,
        enableBackground,
        _classNames,
        ...props
    } = p

    const defaultButtonProps = {
        Component: 'div',
        size: 'checkbox-' + (size.split('-')[1]),
        variant: 'contained',
        icon: active ? <CloseIcon /> : <SvgIcon />,
        color: disabled ? 'disabled' : color,
        style: {
            transition: 'none'
        },
        effect,
        disableElevation,
        disabled
    }

    return (
        <div {...props} className={clsx(_classNames.customCheckbox)} ref={ref}>
            <Button {...defaultButtonProps} />
        </div>
    )
})
