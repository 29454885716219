import { runPostTypesConfigSync } from '../../postTypes'

const messages = {
    notFoundNotAvailable: (p = {}) => {
        const { typeText = 'a tartalom', user } = p
        return `Ez ${typeText} ${user?._id ? '' : ''}nem elérhető`
    },
    notFoundLoginText: 'Log into your account because there are pages that only someone with the right permissions can see',
    thereAreNoEntries: 'There are no entries',
    cookieShortDesc: 'We use cookies on the website for a better user experience',
    leaveWarningMessage: 'Leaving the page will lose your changes, are you sure this is what you want?',
    preventClickAgain: 'The page is loading...',
    preventClickDone: 'This page has already loaded',
    offlineWarning: 'No internet connection',
    onlineAgain: 'The internet connection has been restored',
    newReleaseWarningContent: 'The Digby application has been updated, reloading the page is necessary for further normal operation',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'messages' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default messages
