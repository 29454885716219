import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'category'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete categories',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },

            [ns + 'More']: 'More',
            [ns + 'SortRelevance']: 'Relevance'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Categories',
            ['my' + Ns + 'Menu']: 'My categories',
            ['new' + N + 'Menu']: 'New category',
            [ns + 'Menu']: 'Categories',
            ['deleted' + Ns + 'Menu']: 'Deleted categories',
            ['banned' + Ns + 'Menu']: 'Permanently deleted categories',
            ['protection' + Ns + 'Menu']: 'Protected categories',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new category has been saved',
            ['save' + N + 'SuccessMessage']: 'The category has been saved',
            ['delete' + N + 'Success']: 'The category has been deleted',
            ['delete' + Ns + 'Success']: 'The categories have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the category?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the categories?',
            ['ban' + N + 'Success']: 'The category has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the category?',
            ['approve' + N + 'Success']: 'The category has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the category?',
            ['protection' + N + 'Success']: 'The category has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the category as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no categories'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Category',
            [ns + 'Title']: 'Categories',
            ['new' + N + 'Title']: 'New category',
            ['edit' + N + 'Title']: 'Edit category',
            ['user' + Ns + 'Title']: 'Categories',
            ['userDeleted' + Ns + 'Title']: 'Deleted categories',
            ['my' + Ns + 'Title']: 'My categories',
            ['myDeleted' + Ns + 'Title']: 'Deleted my categories',

            ['dialogDelete' + N + 'Title']: 'Delete category',
            ['dialogBan' + N + 'Title']: 'Permanently delete category',
            ['dialogApprove' + N + 'Title']: 'Approve category',
            ['dialogProtection' + N + 'Title']: 'Mark category as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove category protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete categories',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted categories',
            ['deleted' + Ns + 'Title']: 'Deleted categories',
            ['missingData' + Ns + 'Title']: 'Missing data categories',
            ['protected' + Ns + 'Title']: 'Protected categories',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status categories',

            ['select' + Ns]: 'Select categories',
            ['select' + N]: 'Select category'
        }
    }
}
