import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const { name = 'file' } = p

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete files',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',

            [ns + 'SortRelevance']: 'Relevance',

            ['cancelAbortUpload' + Ns + 'Text']: 'Cancel',
            ['abortUpload' + Ns + 'Text']: 'Abort upload',
            ['upload' + Ns + 'HelperText']: 'Click or drag files here',
            ['upload' + N + 'HelperText']: 'Click or drag the file here',
            [n + 'GalleryMoreItems']: ({ length }) => '+' + length + ' more items',

            [ns + 'OrSelectFromListOpenLabel']: 'Media library',

            [n + 'CoverPropsRotateLabel']: 'Rotate',
            [n + 'CoverPropsScaleLabel']: 'Scale',

            [n + 'AvatarPropsRotateLabel']: 'Rotate',
            [n + 'AvatarPropsScaleLabel']: 'Scale'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Files',
            ['my' + Ns + 'Menu']: 'My files',
            ['new' + N + 'Menu']: 'Upload files',
            [ns + 'Menu']: 'Files',
            ['deleted' + Ns + 'Menu']: 'Deleted files',
            ['banned' + Ns + 'Menu']: 'Permanently deleted files',
            ['protection' + Ns + 'Menu']: 'Protected files',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + Ns + 'SuccessMessage']: 'Files uploaded',
            ['save' + N + 'SuccessMessage']: 'The file has been saved',
            ['delete' + N + 'Success']: 'The file has been deleted',
            ['delete' + Ns + 'Success']: 'The files have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the file?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the files?',
            ['ban' + N + 'Success']: 'The file has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the file?',
            ['approve' + N + 'Success']: 'The file has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the file?',
            ['protection' + N + 'Success']: 'The file has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the file as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no files',

            ['abortUpload' + Ns + 'Question']: 'Abort the upload?',
            ['abortUpload' + Ns + 'Success']: 'Upload aborted',
            ['new' + Ns + 'ClientErrorMessage']: 'An error occurred while opening the file, try refreshing the page'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'File',
            [ns + 'Title']: 'Files',
            ['new' + N + 'Title']: 'New file',
            ['edit' + N + 'Title']: 'Edit file',
            ['user' + Ns + 'Title']: 'Files',
            ['userDeleted' + Ns + 'Title']: 'Deleted files',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted files',
            ['my' + Ns + 'Title']: 'My files',
            ['myDeleted' + Ns + 'Title']: 'Deleted my files',
            ['myBanned' + Ns + 'Title']: 'Permanently deleted my files',

            ['dialogDelete' + N + 'Title']: 'Delete file',
            ['dialogBan' + N + 'Title']: 'Permanently delete file',
            ['dialogApprove' + N + 'Title']: 'Approve file',
            ['dialogProtection' + N + 'Title']: 'Mark file as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove file protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete files',
            ['dialogAbortUpload' + Ns + 'Title']: 'Abort upload',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted files',
            ['deleted' + Ns + 'Title']: 'Deleted files',
            ['missingData' + Ns + 'Title']: 'Missing data files',
            ['protected' + Ns + 'Title']: 'Protected files',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status files',

            ['select' + Ns]: 'Select files',
            ['select' + N]: 'Select file'
        }
    }
}
