import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'method'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete methods',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',

            [ns + 'SortRelevance']: 'Relevance',

            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            }
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Methods',
            ['my' + Ns + 'Menu']: 'My methods',
            ['new' + N + 'Menu']: 'New method',
            [ns + 'Menu']: 'Methods',
            ['deleted' + Ns + 'Menu']: 'Deleted methods',
            ['banned' + Ns + 'Menu']: 'Permanently deleted methods',
            ['protection' + Ns + 'Menu']: 'Protected methods',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new method has been saved',
            ['save' + N + 'SuccessMessage']: 'The method has been saved',
            ['delete' + N + 'Success']: 'The method has been deleted',
            ['delete' + Ns + 'Success']: 'The methods have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the method?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the methods?',
            ['ban' + N + 'Success']: 'The method has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the method?',
            ['approve' + N + 'Success']: 'The method has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the method?',
            ['protection' + N + 'Success']: 'The method has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the method as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no methods'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Method',
            [ns + 'Title']: 'Methods',
            ['new' + N + 'Title']: 'New method',
            ['edit' + N + 'Title']: 'Edit method',
            ['user' + Ns + 'Title']: 'Methods',
            ['userDeleted' + Ns + 'Title']: 'Deleted methods',
            ['my' + Ns + 'Title']: 'My methods',
            ['myDeleted' + Ns + 'Title']: 'Deleted my methods',

            ['dialogDelete' + N + 'Title']: 'Delete method',
            ['dialogBan' + N + 'Title']: 'Permanently delete method',
            ['dialogApprove' + N + 'Title']: 'Approve method',
            ['dialogProtection' + N + 'Title']: 'Mark method as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove method protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete methods',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted methods',
            ['deleted' + Ns + 'Title']: 'Deleted methods',
            ['missingData' + Ns + 'Title']: 'Missing data methods',
            ['protected' + Ns + 'Title']: 'Protected methods',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status methods',

            ['select' + Ns]: 'Select methods',
            ['select' + N]: 'Select method'
        }
    }
}
