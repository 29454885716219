import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'banner'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete banners',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['moreFromMy' + Ns + 'Title']: 'More from my banners',
            ['moreFromUser' + Ns + 'Title']: 'More banners from user',

            [ns + 'SortRelevance']: 'Relevance'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Banners',
            ['my' + Ns + 'Menu']: 'My banners',
            ['new' + N + 'Menu']: 'New banner',
            [ns + 'Menu']: 'Banners',
            ['deleted' + Ns + 'Menu']: 'Deleted banners',
            ['banned' + Ns + 'Menu']: 'Permanently deleted banners',
            ['protection' + Ns + 'Menu']: 'Protected banners',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new banner has been saved',
            ['save' + N + 'SuccessMessage']: 'The banner has been saved',
            ['delete' + N + 'Success']: 'The banner has been deleted',
            ['delete' + Ns + 'Success']: 'The banners have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the banner?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the banners?',
            ['ban' + N + 'Success']: 'The banner has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the banner?',
            ['approve' + N + 'Success']: 'The banner has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the banner?',
            ['protection' + N + 'Success']: 'The banner has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the banner as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no banners'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Banner',
            [ns + 'Title']: 'Banners',
            ['new' + N + 'Title']: 'New banner',
            ['edit' + N + 'Title']: 'Edit banner',
            ['user' + Ns + 'Title']: 'Banners',
            ['userDeleted' + Ns + 'Title']: 'Deleted banners',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted banners',
            ['my' + Ns + 'Title']: 'My banners',
            ['myDeleted' + Ns + 'Title']: 'Deleted my banners',
            ['myBanned' + Ns + 'Title']: 'Permanently deleted my banners',

            ['dialogDelete' + N + 'Title']: 'Delete banner',
            ['dialogBan' + N + 'Title']: 'Permanently delete banner',
            ['dialogApprove' + N + 'Title']: 'Approve banner',
            ['dialogProtection' + N + 'Title']: 'Mark banner as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove banner protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete banners',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted banners',
            ['deleted' + Ns + 'Title']: 'Deleted banners',
            ['missingData' + Ns + 'Title']: 'Missing data banners',
            ['protected' + Ns + 'Title']: 'Protected banners',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status banners',

            ['select' + Ns]: 'Select banners',
            ['select' + N]: 'Select banner'
        }
    }
}
