import { runPostTypesConfigSync } from '../../postTypes'

const labels = {
    notFoundButtonBack: 'Previous page',
    notFoundButtonHome: 'Home page',
    notFoundButtonLogin: 'Login',
    notFoundButtonSignup: 'Sign up',
    acceptCookiesDetailsLabel: 'Details',
    acceptCookiesLabel: 'Accept',
    acceptCookiesClose: 'Close',
    selectAnOperationLabel: 'Select an operation',
    searchLabel: 'Search',
    themeControlsMode: 'Color scheme',
    themeControlsDarkMode: 'Dark',
    themeControlsLightMode: 'Light',
    themeControlsAutoMode: 'Default',
    themeControlsAutoModeSec: 'According to device setting',
    themeControlsContrast: 'Contrast',
    themeControlsHighContrast: 'Higher contrast',
    leaveWarningCancel: 'Cancel',
    leaveWarningSubmit: 'Proceed',
    mobileMenu: 'Menu',
    logoAreaLabel: 'Home page',
    newReleaseCancelText: 'Continue',
    newReleaseSubmitText: 'Reload',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'labels' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default labels
