import { runPostTypesConfigSync } from '../../postTypes'

const menus = {
    homeMenu: 'Home',
    backMenu: 'Back',
    themeControlsMenu: 'Theme colors',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'menus' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default menus
